.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}


@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeOutDown {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100% , 0);
    }
}
@keyframes fadeOutDown {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translate3d(0, 100% , 0);
    }
}

.fadeOutDown {
    -webkit-animation-name: fadeOutDown;
    animation-name: fadeOutDown;
}
.alerts {
    direction: rtl;
    .alert {
      direction: ltr;
      .animated;
      margin: 12px 0 0 0; 
    }
    &.fixed {
      @media (max-width: @screen-xs-max) {
        bottom: 0;
        left: 0;
        border-radius: 0;
        width: 100%;
        z-index: 10001;
      }
    }
}
.alert {
    color: #fff;
    border: 0;
    position: relative;
    &.bottomleft {
      .animated;
    }
    &.fixed {
      @media (max-width: @screen-xs-max) {
        bottom: 0;
        left: 0;
        border-radius: 0;
        width: 100%;
        z-index: 10001;
      }
    }
  .close {
      .transition(all .3s ease-in-out);
      text-shadow: none;
      font-weight: 400;
      top: 10px;
      right: 10px;
      border-radius: 50%;
      font-size: 20px;
      display: block;
      line-height: 30px;
      text-align: center;
      height: 30px;
      width: 30px;
      position: absolute;
      color: #fff;
      .opacity(.75);
      &:hover, &:focus {
        color: #fff;
        .opacity(1);
        .rgba-black(.1);
      }
  }

}
.alert-dismissable, .alert-dismissible {
  padding-right: 48px;
}

.alert-popup {
    .opacity(0);
    background: @gray-darker;
    &.alert-danger, &.alert-success {
      &:before {
        .icon;
        font-size: 20px;
        vertical-align: middle;
        width: 24px;
      }
    }

    &.alert-success {
      &:before {
        content: "\f26b";
        color: @brand-success;
      }
    }
    &.alert-danger {
      &:before {
        content: "\f1f1";
        color: @brand-danger;
      }
    }
}

.alert-autohide {
  animation: 1s fadeInUp ease;
}

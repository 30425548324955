// .teasers {
//     margin: @line-height-computed 0;
//     @media (min-width: @screen-md-min) {
//         display: flex;
//         flex-wrap: wrap;
//     }
//     a {
//         background: @gray-lighter;
//         display: block;
//         margin-bottom: 12px;
//         @media (min-width: @screen-md-min) {
//             width: 32%;
//             margin: 1%;
//             &:nth-of-type(3n) {
//                 margin-right: 0;    
//             }
//             &:nth-of-type(3n+1) {
//                 margin-left: 0; 
//             }
//         }
//         padding-bottom: 6px;
//         &.has-desc {
//             padding-bottom: 56px;
//         }
//         position: relative;
//         text-decoration: none;
//         .text-center;
//         .transition(all .3s);
//         h2 {
//             font-size: @font-size-large;
//             margin-top: 0;
//             padding-top: 16px;
//         }
//         h2, p {
//             .transition(all .3s);
//         }
//         p {
//             color: @gray;
//             margin: 0;   
//             padding: 0 12px;
//         }
//         figure {
//             overflow: hidden;
//             height: 0;
//             padding-top: 100%;
//             position: relative;
//             img {
//                 display: block;
//                 width: 100%;
//                 height: 100%;
//                 margin: auto;
//                 position: absolute;
//                 top: -100%;
//                 right: -100%;
//                 bottom: -100%;
//                 left: -100%;
//             }
//         }
//         img {
//             -webkit-backface-visibility: hidden;
//             .transition(transform .3s);
//         }
//         .more {
//             position: absolute;
//             left: 0;
//             bottom: 12px;
//             width: 100%;
//         }
//         &:hover {
//             background: @brand-primary;
//             h2 {
//                 color: #fff;
//             }
//             p {
//                 color: rgba(255,255,255,.7);
//             }
//             .btn {
//                 background: #fff;
//                 color: @brand-primary;
//             }
//             img {
//                 transform: scale(1.1);
//             }
//         }
//         &.disabled {
//             .opacity(.3);
//         }
//     }
// }
.teasers {
    .row;
    .list-unstyled;
    margin-left: -12px;
    > li {
        &.disabled {
            figure span, strong, .actions i {
                .opacity(.3);
            }
        }
        .make-xs-column(6);
        .make-sm-column(4);
        .make-md-column(3);
        padding-left: 12px;
        padding-right: 12px;
        margin-bottom: 24px;
        a {
            display: block;
            text-decoration: none;
            &:hover {
                figure {
                    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
                }
                strong {
                    color: @brand-primary;
                }
            }
        }
    }
    img {
        width: 100%;
        height: auto;
        position: absolute;
    }
    figure {
        // border: 1px solid @gray-lighter;
        border-radius: 2px;
        .transition(box-shadow .3s);
        height: 0;
        padding-bottom: 100%;
        position: relative;
        overflow: hidden;
        box-shadow: 0;
        &:before {
            content: "";
            border-radius: 2px;
            position: absolute;
            z-index: 10;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 1px solid rgba(0,0,0,.12);
        }
        & > span > i {
            position: absolute;
            font-size: @font-size-h1 * 4;
            top: 50%;
            left: 0;
            width: 100%;
            .text-center;
            .transition(color .3s);
            transform: translateY(-50%);
        }
    }

    strong {
        color: @gray-darker;
        .transition(color .3s);
        display: inline-block;
        //white-space: nowrap;
        overflow: hidden;
        margin: 6px 0;

        //word-wrap: break-word;
        min-height: (@line-height-base * 2) + 0em;
        max-height: (@line-height-base * 2) + 0em;
        line-height: @line-height-base + 0em;
        text-overflow: ellipsis;
    }
    .zmdi-folder, .zmdi-children {
        color: #fbdf7a;
    }
    .zmdi-page, .zmdi-document {
        color: #1e90ff;
    } 
    .actions {
        position: absolute;
        right: 20px;
        top: 8px;
    }  
}

//.teaser--typeIcon {
//    position: absolute;
//    bottom: 8px;
//    left: 8px;
//}
#nprogress {
    position: absolute;
    z-index: 99999;
}

#nprogress .bar {
    background: @panel-footer-bg;
    z-index: 99999;
}

/* Fancy blur effect */
#nprogress .peg {
    box-shadow: none;
}

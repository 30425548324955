// Highlight a target // -----------------------------------------------------------------------------------------------
@keyframes highlight {
    0% {
        background-color: lighten(@amber, 35%);
    }
    100% {
        background-color: transparent;
    }
}

.u-highlightMe,
.u-highlight:target,
:target:not(#comments) .u-highlight {
    animation-duration: 5s;
    animation-timing-function: ease-in-out;
    animation-name: highlight;
}
.pagination {
    width: 100%;
    .text-center;
    margin: 0;
    > li {
        margin: 0 2px;
        display: inline-block;
        vertical-align: top;
        > a, > span {
            background: @gray-lighter;
            border-radius: 50% !important;
            border: 0;
            padding: 0;
            font-size: @font-size-base;
            font-weight: 500;
            width: 34px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            z-index: 1;
            position: relative;
            .transition(all .3s);
            &:hover {
                background: @gray-light;
                color: #fff;
            }
        }
    }
    > .active {
        > a, > a:hover {
            background: @brand-primary;
            color: #fff;
        }
    }
    > .disabled {
        > span, > span:hover,
        > a, > a:hover {
            background: @gray-lighter;
            color: @gray-light;
        }
    }
}


.page-nav {
    span {
        .hidden-xs;
    }
    .text-center;
    text-transform: uppercase;
    font-weight: 500;
    overflow: hidden;
    position: relative;
    min-height: 44px;
    .prev, .next {
        position: absolute;
    }
    .prev {
        left: 0;
    }
    .next {
        right: 0;
    }
    a {
        display: inline-block;
        padding: 16px;
    }
    i {
        font-size: @font-size-h3;
        position: relative;
        top: 1px;
        vertical-align: top;
        line-height: 1;
        width: 20px;
    }
}
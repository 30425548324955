.newsitems {
    .list-unstyled;
    .row;
    li {
        .make-sm-column(6);
        .make-md-column(4);
        margin-bottom: @grid-gutter-width / 2;
        @media (min-width: @screen-sm-min) {
            margin-bottom: @grid-gutter-width;
        }
    }
    .card {
        margin: 0;
        > a {
            display: block;
            .transition(all .3s);
            h2, p {
                .transition(color .3s);
            }
            &:hover {
                background: lighten(@gray-lighter, 5%);
                img {
                    transform: scale(1.1);
                }
                h2, p {
                    color: @gray-darker;
                }
            }
        }
    }
    h2, p {
        display: block;
        display: -webkit-box;
        line-height: @line-height-computed;
        height: @line-height-computed * 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    h2 {
        font-size: @font-size-large;
        margin-top: @line-height-computed / 4;
    }
    p {
        height: @line-height-computed * 3;
        -webkit-line-clamp: 3;
        color: @gray-dark;
        margin-bottom: @line-height-computed / 4;
    }
    time {
        font-size: 44px;
        line-height: 48px;
        text-transform: uppercase;
        .text-center;
        display: block;
        color: #fff;
        position: relative;
        z-index: 20;
        span {
            display: block;
            font-size: @font-size-h2;
            line-height: @font-size-h1;
        }
    }
    .card-intro {
        background-color: @gray-light;
        position: relative;
        overflow: hidden;
        padding: 21px;
        margin: 0;
        .transition(all .3s);
        &:before {
            .rgba-black(.3);
            position: absolute;
            z-index: 10;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            content: '';
            display: block;
        }
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
         z-index: 5;
         .transition(transform .3s);
    }
    .card-footer {
        border-top: 1px solid @gray-lighter;
        overflow: hidden;
    }
}
.reaction {
    .card-content {
        dt, dd {
            white-space: normal;
            overflow: auto;
            text-overflow: inherit;
        }
    }
}

.newsitems {
    .disabled {
        opacity: 0.5;
    }
}

.card-body {
    &.disabled {
        opacity: 0.5;
    }
}

.btn-insert {
    margin: @line-height-computed 0;
}
.btn {
    background: transparent;
    // border: 0;
    color: @gray-darker;
    font-weight: 500;
    border-radius: @border-radius-small;
    padding-left: @padding-large-horizontal;
    padding-right: @padding-large-horizontal;
    text-transform: uppercase;
    white-space: normal; // Overwrites bootstrap's standard styling which is no-wrap
    .transition(all .3s);
    &:hover {
        color: @gray-darker;
        background: @gray-lighter;
        text-decoration: none;
    }
}


.btn-default, .btn-primary, .btn-danger, .btn-success {
    box-shadow: 0 0 2px rgba(0,0,0,.12),0 2px 4px rgba(0,0,0,.24);
    &:hover {
        box-shadow: 0 0 4px rgba(0,0,0,.14),0 4px 8px rgba(0,0,0,.28);
    }
    &:focus {
        box-shadow: 0 0 6px rgba(0,0,0,.16),0 6px 12px rgba(0,0,0,.32);
    }
}


.btn-default {
    background: @white;
    border-color: @white;
    color: @gray-darker;
    &:hover, &:focus {
        background: @white;
         border-color: @white;
        color: @gray-darker;
    }
}
.btn-primary {
    background: @brand-primary;
    border-color: @brand-primary;
    color: #fff;
    &:hover, &:focus {
        background: @brand-primary;
        border-color: @brand-primary;
        color: #fff;
    }
}

.btn-secondary {
    background: @brand-secondary;
    border-color: @brand-secondary;
    color: #fff;
    &:hover, &:focus {
        background: @brand-secondary;
        border-color: @brand-secondary;
        color: #fff;
    }
}

.btn-danger {
    background: @white;
    border-color: @white;
    color: @brand-danger;
    &:hover, &:focus {
        background: @brand-danger;
        border-color: @brand-danger;
        color: #fff;
    }
}

.btn-success {
    background: @brand-success;
    border-color: @brand-success;
    color: #fff;
    &:hover, &:focus {
        background: @brand-success;
        border-color: @brand-success;
        color: #fff;
    }
}
.btn-info {
    background: @white;
    border-color: @white;
    color: @brand-info;
    &:hover, &:focus {
        background: @brand-info;
        border-color: @brand-info;
        color: #fff;
    }
}




.btn-submit {
    margin-right: 12px;
}

.btn-round {
    cursor: pointer;
    box-shadow: 0 0 4px rgba(0,0,0,.14),0 4px 8px rgba(0,0,0,.28);
    border-radius: 50%;
    height: 56px;
    width: 56px;
    .text-center;
    padding: 0;
    &:hover {
        box-shadow: 0 0 6px rgba(0,0,0,.16),0 6px 12px rgba(0,0,0,.32);
    }
    i {
        font-size: 28px;
        position: relative;
        top: 14px;
        .transition(all .3s ease-in-out);
    }
    // &:hover {
    //     i {
    //         .rotate(-360deg);
    //     }
    // }
}
.btn-icon {
    background: transparent;
    color: darken(@gray-light, 20%);
    display: inline-block;
    border: 0;
    cursor: pointer;
    height: 40px;
    padding: 5px 0;
    &:hover, &:focus {
        color: @gray-darker;
    }
    &:focus {
        i {
            .rgba-black(.1);
        }
    }
    i {
        .transition(all .3s ease-in-out);
        font-size: @font-size-h1;
        border-radius: 50%;
        .text-center;
        padding: 0;
        line-height: 30px;
        width: 30px;
    }
}
.btn-add {
    background: @brand-accent;
    color: #fff;
    position: relative;
    z-index: 20;
    &:hover, &:focus {
        background: @brand-accent;
        color: #fff;
    }
}

.fab {
    ul {
        .list-unstyled;
        clear: both;
        .transition(opacity .3s);
        position: absolute;
        width: 56px;
        left: 0;
        bottom: 56px;
        margin: 0;
        z-index: 1000;
        .text-center;
        visibility: hidden;
        li {
            opacity: 0;
            .transition(all .3s ease);
            transform: scale(0) translateY(100%);
            margin-bottom: 12px;
            &:nth-child(1) {
                .transition(all .3s ease .2s);
            }
            &:nth-child(2) {
                .transition(all .3s ease .1s);
            }            
        }
        .btn-round {
            height: 48px;
            width: 48px;
            i {
                color: @gray-dark;
                font-size: 22px;
                top: 12px;
            }
        }
    }
    &.open {
        ul {
            li {
            opacity: 1;
            visibility: visible;
                transform: scale(1) translateY(0px);
            }
        }
    }
}




.reservaties {
	#content > .container {
		max-width: none;
	}
}

.bg-red, .modal-red .modal-header, .modal-red .btn-primary { background-color: @red !important; }
.bg-pink, .modal-pink .modal-header, .modal-pink .btn-primary { background-color: @pink !important; }
.bg-purple, .modal-purple .modal-header, .modal-purple .btn-primary { background-color: @purple !important; }
.bg-deeppurple, .modal-deeppurple .modal-header, .modal-deeppurple .btn-primary { background-color: @deeppurple !important; }
.bg-indigo, .modal-indigo .modal-header, .modal-indigo .btn-primary { background-color: @indigo !important; }
.bg-blue, .modal-blue .modal-header, .modal-blue .btn-primary { background-color: @blue !important; }
.bg-lightblue, .modal-lightblue .modal-header, .modal-lightblue .btn-primary { background-color: @lightblue !important; }
.bg-cyan, .modal-cyan .modal-header, .modal-cyan .btn-primary { background-color: @cyan !important; }
.bg-teal, .modal-teal .modal-header, .modal-teal .btn-primary { background-color: @teal !important; }
.bg-green, .modal-green .modal-header, .modal-green .btn-primary { background-color: @green !important; }
.bg-lightgreen, .modal-lightgreen .modal-header, .modal-lightgreen .btn-primary { background-color: @lightgreen !important; }
.bg-lime, .modal-lime .modal-header, .modal-lime .btn-primary { background-color: @lime !important; }
.bg-yellow, .modal-yellow .modal-header, .modal-yellow .btn-primary { background-color: @yellow !important; }
.bg-amber, .modal-amber .modal-header, .modal-amber .btn-primary { background-color: @amber !important; }
.bg-orange, .modal-orange .modal-header, .modal-orange .btn-primary { background-color: @orange !important; }
.bg-deeporange, .modal-deeporange .modal-header, .modal-deeporange .btn-primary { background-color: @deeporange !important; }
.bg-brown, .modal-brown .modal-header, .modal-brown .btn-primary { background-color: @brown !important; }
.bg-bluegray, .modal-bluegray .modal-header, .modal-bluegray .btn-primary { background-color: @bluegray !important; }


.calendar {
    background: @yellow;
    color: @gray-darker;
    position: relative;
    .text-center;
    padding: 16px 24px;
    border-radius: @border-radius-small @border-radius-small 0 0;
    h2 {
        color: @gray-darker;
        margin-top: 0;
        margin-bottom: @grid-gutter-width;
    }
    .date {
        font-size: 44px;
        line-height: 48px;
        text-transform: uppercase;
        margin: 0;
        span {
            display: block;
            font-size: @font-size-h2;
            line-height: @font-size-h1;
            &.year {
                color: @gray-dark;
            }
        }
    }


        .prev, .next {
        	background: #333;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            display: inline-block;
            text-align: center;
            position: absolute;
            top: 16px;
            .transition(background .3s);
            &:hover {
                background: @gray-darker;
                > i {
                    color: @yellow;
                }
            }
        > i {
            vertical-align: middle;
            color: #fff;
            font-size: @font-size-h1;
            line-height: 32px;
            }
        }

        .prev {
            left: 10px;
        }
        .next {
            right: 10px;
        }


}

.calendar-sidebar {
	.make-md-column(3);
}
.calendar-content {
	.make-md-column(9);
	.btn-group {
		position: absolute;
		top: 16px;
		right: 32px;
		z-index: 999;
	}
	.btn {
		box-shadow: none;
		border: 1px solid @input-border;
		margin-top: 1px;
		color: @gray;
		&:hover {
			background: transparent;
			box-shadow: none;
			color: @brand-primary;
		}
		&.active {
			color: @brand-primary;
			background: @gray-lighter;
		}
	}
}

.calendar-select, .calendar-select ul {
	.nav;
	margin: 0;
	li {
		a {
			color: @gray-darker;
			&:hover, &:focus {
				background: none;
			}
		}
	}
}
.calendar-select {
	overflow: hidden;
	label {
		padding: 10px 15px 10px 35px;
		display: block;
		cursor: pointer;
	}
	ul {
		li:last-child {
			margin-bottom: 10px;
		}
	}
	> li {
		border-bottom: 1px solid @gray-lighter;
		@media (max-width: @screen-md-min) {
			&:not(:first-child) {
				float: left;
				width: 50%;
				border: none;
				> a, label {
					padding-bottom: 0;
				}
			}
		}
		&:last-child {
			border: 0;
		}
	}

}



.fc {
	.card;
	max-width: none;
	margin-bottom: @grid-gutter-width;
}
.fc .fc-toolbar>*>* {
	margin: 0;
}

.fc td, .fc th {
	border-color: @gray-lighter;
}

.fc th {
	font-weight: 400;
}

.fc table tr > td:first-child {
	border-left-width: 0;
}

#calendar .fc-toolbar .fc-center {
	position: relative;
	vertical-align: top;

	.ui-button {
		border: 0;
		background: none;
		padding: 0;
		outline: none !important;
		text-align: center;
		width: 32px;
		height: 32px;
		border-radius: 50%;
		color: @gray;
		transition: background 0.3s, color 0.3s;
		&:hover {
			background: @gray-lighter;
			color: @gray-darker;
		}
		> span {
			position: relative;
			.icon;
			font-size: @font-size-h2;
			line-height: 100%;
			width: 32px;
			display: block;
			&:before {
				position: relative;
				z-index: 1;
			}
			&.ui-icon-circle-triangle-w:before {
				content: "\f2fa";
			}

			&.ui-icon-circle-triangle-e:before {
				content: "\f2fb";
			}
		}
	}
}

#calendar .fc-left {
	button {
		.btn;
		.btn-sm;
		box-shadow: none;
		border: 1px solid @input-border;
		margin-top: 1px;
		color: @gray;
		position: absolute;
		height: 30px;
		&:hover {
			background: #fff;
			box-shadow: none;
			color: @brand-primary;
		}
	}
}


#calendar .fc-day-header {
	color: @gray-light;
	text-align: left;
	text-transform: uppercase;
	font-size: @font-size-base;
	border-bottom-width: 0;
	border-right-color: @gray-lighter;
	padding: 10px 12px;
}

#calendar .fc-day-number {
	padding-left: 10px !important;
	color: @gray-light;
	text-align: left !important;
	@media (min-width: @screen-md-min) {
		font-size: @font-size-h2;
	}
}

// #calendar .fc-day-grid-event {
// 	margin: 1px 9px 0;
// }

.fc-toolbar {
	padding: 16px;
	margin-bottom: 0;
	position: relative;
	h2 {
		margin: 0 16px !important;
		line-height: 32px;
		// width: 180px;
	}
}




.fc-event {
	background: @brand-primary;
	padding: 0;
	font-size: @font-size-small;
	border-radius: 0;
	border: 0;
}

.fc-event .fc-title {
	padding: 2px 8px;
	display: block;
}

.fc-event .fc-time {
	display: inline-block;
	background: rgba(0, 0, 0, 0.2);
	padding: 2px 6px;
	margin: 0 0 5px;
	font-size: 10px;
}

.fc-view,
.fc-view > table {
	border: 0;
	overflow: hidden;
}

.fc-view > table > tbody > tr > .ui-widget-content {
	border-top: 0;
}

div.fc-row {
	margin-right: 0 !important;
	border: 0 !important;
}

.fc-today {
	color: @blue !important;
}

hr.fc-divider {
	border-width: 1px;
	border-color: @gray-lighter;
}

.fc-day-grid-container.fc-scroller {
	height: auto !important;
	overflow: hidden !important;
}

.event-follow {
	@media (min-width: @screen-sm-min) {
		position: absolute;
	    right: @modal-inner-padding;
	    z-index: 10;
	}
}

#calendarApp {
	.inactive {
		opacity: 0.5;
	}
}

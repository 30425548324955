.dropzone, .uploadField {
	display: block;
	border: 4px dashed @gray-lighter;
	// .transition(border .3s);
	h2 {
		// .transition(color .3s);
	}
	i {
		color: @gray-light;
	}
	h2, p {
		color: @gray;
	}
	&.dz-drag-hover {
		border: 4px dashed @brand-primary;
		h2 {
			color: @brand-primary;
		}
		.dz-message {
			opacity: 1;
		}
	}
	&.dz-started {
		border: 0;
		padding: 0;
		.row;
		margin: 6px -6px 0;
	}

	.dz-preview {
		.make-xs-column(6);
		.make-sm-column(4);
		.make-md-column(2);
		padding-left: 6px;
		padding-right: 6px;
		margin: 6px 0;
		.dz-image, .dz-progress {
			border-radius: 0;
		}
		.dz-image {
			background: @gray-lighter !important;
			border-radius: 0 !important;
			width: 100%;
			height: 0;
			min-height: 0;
			position: relative;
			padding-top: 100%;
			img {
				position:absolute;
			    left: -100%;
			    right: -100%;
			    top: -100%;
			    bottom: -100%;
			    margin: auto;
			    width: auto;
			    height: 100%;
			}
		}
		&.dz-fullwidth {
			width: 100%;
			.dz-image {
				padding-top: 75%;
			}
		}
		.dz-progress {
			border: 0;
			opacity: 0;
			height: 4px;
			margin-top: -2;
			.dz-upload {
				background: @brand-success;
			}
		}
		.dz-details {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		.dz-remove {
			.rgba-black(.8);
			position: absolute;
			right: 6px;
			bottom: 0;
			z-index: 999;
			width: 24px;
			height: 24px;
			overflow: hidden;
			text-indent: -999em;
			.transition(background .3s);
			&:before {
				color: #fff;
            	.icon;
	            content: "\f154";
	            font-size: @font-size-h4;
	            line-height: 24px;
	            height: 24px;
	            width: 24px;
	            position: absolute;
	            right: 0;
	            text-indent: 0;
	        }
	        &:hover {
	        	background: @brand-danger;
	        }
		}
		.dz-error-mark, .dz-success-mark {
			margin: -24px 0 0 -24px;
			i {
				text-align: center;
				line-height: 48px;
				font-size: 48px;
				height: 48px;
				width: 48px;
				color: #fff;
			}
		}
	}
}

.dropzone .dz-preview .dz-image {
	border-radius:0px;
}

.dropzone .dz-preview .dz-progress {
	border-radius:0px;
}

.dz-preview.gu-mirror {
	width: 100px;
	height: 100px;
	display: block;
	overflow: hidden;
}
.dz-preview.gu-mirror > * {
	display: none;
}
.dz-preview.gu-mirror > .dz-image {
	display: block !important;
}

.card {
    background: #fff;
    position: relative;
    border: 0;
    border-radius: 0;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
    margin: 0 auto (@grid-gutter-width / 2);
    max-width: 800px;
    @media (min-width: @screen-sm-min) {
        border-radius: 2px;
    }
    strong {
        // color: @gray-darker;
        a {
            color: @gray-darker;
        }
    }
    .actions {
        position: absolute;
        right: 8px;
        top: 16px;
    }
}
.card-nobg {
    background: transparent;
    box-shadow: none;
    border: 0;
    .card-body {
        padding: 8px 16px;
    }
    dt, dd, a {
        color: @gray;
    }
    a {
        &:hover {
            color: @gray-darker;
        }
    }
}

.card-header {
    border-bottom: 1px solid @gray-lighter;
    border-radius: 2px 2px 0 0;
    font-weight: 500;
    color: @gray-dark;
    border-radius: 0;
    padding: 12px 16px;
     overflow: hidden;
    h2, h3 {
        margin: 0;
        padding: 0;
        small {
            display: block;
            font-size: @font-size-base;
            font-weight: 400;
            line-height: @line-height-computed;
        }
    }
    &.card-padding {
        padding: 16px 24px;
    }
}
.card-body {
    position: relative;
    padding: 16px;
    border-bottom: 1px solid @gray-lighter;
    .clearfix;

    .table-wrap {
        margin: -16px -16px 16px;
    }

    &:last-child {
        border: 0;
    }
    .card-list & {
        display: table;
        width: 100%;
    }
    &.card-padding {
        padding: 24px;
        .table-wrap {
            margin: -16px -24px 24px;
        }
    }
    &.card-padding-large {
        padding: 36px;
        @media (min-width: @screen-sm-min) {
            padding: 48px;
        }
    }

    .dummy-placeholder {
        border: 5px solid @red;
        text-align: center;
        font-size: 48px;
    }

    .video-wrapper {
        text-align: center;
        video {
            max-width: 100%;
        }
    }
}
.js-contacts .card-body:last-child {
    border-bottom: 1px solid @gray-lighter;
}

a.card-body {
    text-decoration: none;
    &:hover {
        background: @gray-lightest;
    }
    p, dd {
        color: @gray;
    }
    strong {
        color: @gray-darker;
    }
}

.card-footer {
    overflow: hidden;
    a {
        color: @gray;
        display: inline-block;
        padding: 12px 16px;
        &:hover {
            color: @gray-darker;
        }
    }
}

.card-intro {
    background: @brand-primary;
    padding: 24px;
    padding-top: 48px;
    margin: -24px -24px 24px;
    position: relative;

    h1 {
        color: #fff;
        margin: 0;
    }
    .actions {
        > li {
            > a {
                &:hover {
                    .rgba-black(0.1);
                    > i {
                        color: #fff;
                    }
                }
                > i {
                    color: @gray-lighter;
                    }
                }
            }
        > .open  > a {
            .rgba-black(0.1);
            > i {
                color: #fff;
            }
        }
    }
    &.has-bg {
        padding: 64px 0 0;
        position: relative;
        @media (min-width: @screen-md-min) {
            padding-top: 128px;
        }
        h1 {
            background-image: linear-gradient(0deg, fade(@gray-darker, 72%) 0%, fade(@gray-darker, 0%) 100%);
            padding: 24px;
            line-height: 1;
            position: relative;
            z-index: 10;
        }
    }
}
.card-intro-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 5;
    img {
        margin: auto;
        width: 100%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        z-index: 5;
    }
}


.card-avatar, .card-icon {
    height: 40px;
    width: 40px;
    display: table-cell;
    vertical-align: top;
    +.card-content {
        padding-left: 16px;
        .reaction & {
            padding-right: 32px;
        }
    }
}


.avatar-holder {
    display: inline-block;
    position: relative;
    i {
        color: @gray;
        display: none;
        text-shadow: 0 -1px 0 #fff, -1px 0 #fff, 0 2px 0 #fff;
        position: absolute;
        bottom: 0;
        right: 0;
        line-height: 14px;
        font-size: @font-size-large;
        .profile & {
            font-size: @font-size-h1;
            // text-shadow: 1px -2px 0 #fff, -2px 0 #fff, 1px 2px 0 #fff;
            text-shadow: 0 -2px 0 #fff, -2px 0 #fff, 2px 0 #fff, 0 2px #fff;
            // text-shadow: none;
            line-height: 30px;
        }
    }
}
.card-icon {
     i {
        background: @gray-light;
        border-radius: 50%;
        height: 40px;
        line-height: 40px;
        .text-center;
        width: 40px;
        font-size: @font-size-h1;
        color: #fff;
    }
    &.on-call {
        i {
            background: @orange;
        }
    }
}
.card-content {
    display: table-cell;
    vertical-align: top;
    max-width: 1em;
    dt, dd {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    dd {
        p {
            white-space: normal;
            margin: 0;
        }
    }
}
.card-list {
    .card-content {
        vertical-align: middle;
    }
    p {
        margin: 0;
    }
}


.profile-sidebar {
    // background: lighten(@gray-lighter, 4%);
    border-right: 1px solid @gray-lighter;
    position: relative;
    padding: @grid-gutter-width;
    .text-center;

    h2 {
        margin: 0;
    }
    img {
        margin: 0 auto;
    }
    .avatar-holder {
        margin-bottom: @line-height-computed / 2;
    }
}

.reaction {
    position: relative;
    display: block;
    border-bottom: 1px solid @gray-lighter;
    &:last-child {
        border: 0;
    }
}

dl.comment {
    padding: 16px 0;
    display: block;
    border-bottom: 1px solid @gray-lighter;
    &:first-child { 
        padding-top: 0;
    }
    &:last-child {
        border: 0;
        padding-bottom: 0;
    }
}



@media (min-width: @screen-sm-min) {
    .profile {
        display: table;
        width: 100%;
    }
     .profile-sidebar {
        .make-sm-column(4);
        padding: 36px 16px;
     }
     .profile-content {
        .make-sm-column(8);
        padding: 0;
     }
    .profile-sidebar, .profile-content {
        display: table-cell;
        vertical-align: top;
        float: none !important;
    }
}

.card-body.is-empty,
.card-body.is-empty h2 {
    text-align: center;
    color: @gray-light;
}

.card-activity + .card-activity {
    display: none;
}
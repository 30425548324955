.map {
    background: #d8d8d6;
    height: 240px;
    position: relative;
    margin: 15px 0;
    .form-control {
        font-weight: 400;
    }
    > div {
        width: 100%;
        height: 100%;
    }
    // .card-padding & {
    //     margin-left: -24px;
    //     margin-right: -24px;
    // }
    @media (min-width: @screen-sm-min) {
        height: 320px;
        .col-2 & {
            height: 240px;
        }
    }

    h2, p {
        font-family: @font-family-sans-serif;
        font-size: @font-size-base;
        line-height: @line-height-base;
        font-weight: 400;
    }
    img {
        width: auto;
        max-width: none;
    }
}
// .map, .map * { .box-sizing(content-box); }

.map-fs {
    margin: 0;
    position: absolute;
    top: 34px;
    left: 0;
    right: 0;
    bottom: 0;
    height: calc(~'100% - 34px');
    width: 100%;
    z-index: 10;
}
.map-sidebar {
    .make-md-column(4);
    .make-md-column-offset(8);
    z-index: 20;
    padding: 0;
    .actions {
        top: 6px;
    }
    .remark {
        margin: -16px -16px 16px !important;
    }
}
.map-window {
    padding: 24px 0 10px 20px;
    position: relative;
    width: 240px;
}

.gm-style .gm-style-iw {
     font-size: @font-size-base;
}
.choice {
    .make-row();
    .center-block;
    .text-center;
    margin-left: -@grid-gutter-width;

    .modal-fs & {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        height: 160px;
    }
}

.choice-block {
    .make-sm-column(4);
    padding: @grid-gutter-width (@grid-gutter-width / 2);
    border: 1px solid @gray-lighter;
}
.choice-block .zmdi {
    color: @gray-light;
}
.choice-block h2 {
    color: @gray;
}
.choice-block.choice-block--new {
    .make-sm-column-offset(2);
 }
.choice-block.choice-block--existing {
    margin-left: @grid-gutter-width;
}
*, :active, :hover {
    outline: 0 !important;
    -webkit-tap-highlight-color: rgba(0,0,0,0) !important;
}
*, button, input, i, a {
    -webkit-font-smoothing: antialiased;
}
// b, strong,
// https://github.com/yabwe/medium-editor/issues/908
h3, h4, h5, h6 {
    font-weight: 500;
}
p {
    letter-spacing: 0.1px;
}
a {
    word-break: break-word; 
}

a:hover, a:focus {
    text-decoration: none;
}

h1, h2, h3 {
    margin-top: 0;
}
h3 {
    padding-top: 10px;
}

h1 { margin: 0 0 24px; }

//img:not(.avatar) {
//   .img-responsive;
//}

a[ng-click] {
    cursor: pointer;
}

.secondary {
    color: @gray;
    display: block;
}
.ellipsis {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.block-header {
    h2 {
        font-size: @font-size-large;
        line-height: @line-height-computed;
        color: @gray-dark;
        padding: 0 16px;

    }
}
// html, body {
//     height: 100%;
//     overflow: hidden;
// }
// #app {
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     overflow: auto;
// }
th, strong, dt {
    font-weight: 500;
}

html, body {
    height: 100%;
}

body {
    background: @gray-lightest;
}
dt {
    color: @gray-darker;
    .time {
        color: @gray-light;
        font-weight: 400;
        display: inline-block;
        margin-left: 10px;
    }
}
dl {
    overflow: hidden;
    margin: 0;
    &.dl-horizontal {
        dt {
            text-align: left;
            color: @gray-dark;
        }
        dt, dd {
            margin-bottom: @line-height-computed / 4;
        }
    }
    &.dl-vertical {
        .make-sm-column(12);
        margin-bottom: @line-height-computed;
        &.half {
            .make-sm-column(6);
        }
        &.third {
            .make-sm-column(4);
        }
        a {
            color: @text-color;
            &:hover {
                text-decoration: underline;
            }
        }
        dt {
            font-weight: 400;
            color: @gray;
            font-size: @font-size-small;
            line-height: @line-height-computed;
        }
        h2 {
            margin: 0;
            @media (min-width: @screen-sm-min) {
                padding-right: 140px;
            }
        }
    }
}



dt a {
    color: @gray-darker;
    &:hover {
        color: @gray-darker;
        text-decoration: underline;
    }
}
a.tel {
    color: @gray-dark;
}
a.mail {
    display: block;
    &:hover {
        text-decoration: underline;
    }
}

.fixed {
    position: fixed;
    margin: 0;
    z-index: 20;
}
.bottomleft, .bottomright {
    bottom: 20px;
}
.bottomleft {
    left: 20px;
    @media (min-width: @screen-lg) {
        left: @navbar-width + 20;
    }
}
.bottomright {
    right: 20px;
}

.icon {
    display: inline-block;
    font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
    font-size: inherit;
}

.zmdi-children {
    &:before {
        content: '\f228';
    }
}

.zmdi-document {
    &:before {
        content: '\f223';
    }
}

.zmdi-page {
    &:before {
        content: '\f222';
    }
}

.zmdi-nieuws {
    &:before {
        content: '\f266';
    }
}

.zmdi-prikbord {
    &:before {
        content: '\f109';
    }
}

.zmdi-page {
    &:before {
        content: '\f222';
    }
}

.dropdown {
    &.open {
        .btn-icon {
            i {
                color: @gray-darker;
                .rgba-black(.1);
            }
        }
        .tooltip {
            .opacity(0);
        }
    }
}

.actions {
    margin: 0;
    > li {
        display: inline-block;
        vertical-align: baseline;
        > a {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            display: inline-block;
            text-align: center;
            position: relative;
            z-index: 15;
            .transition(background .3s);
            &:hover {
                .rgba-black(0.1);
                > i {
                    color: @gray-darker;
                }
            }
            > i {
                .transition(color .3s);
                vertical-align: middle;
                color: @gray-light;
                font-size: @font-size-h2;
                line-height: 32px;
                }
            }
        }
    > .open  > a {
        .rgba-black(0.1);
        > i {
            color: @gray-darker;
        }
    }
    &.in-modal {
        position: absolute;
        right: 48px;
        top: -44px;

        > li {
            > a {
                &:hover {
                    > i {
                        color: #fff;
                    }
                }
                > i {
                    color: #fff;
                    }
                }
            }
        > .open  > a {
            > i {
                color: #fff;
            }
        }
        
    }
    + h1 {
        margin-right: @line-height-computed;
    }
}

.angular-google-map-container { height: 400px; }

.dropdown-menu {
    display: block;
    border-radius: 0;
    .scale(0);
    .opacity(0);
    .transition(all .15s ease-in-out);
    transform-origin: 100% 0;
    @media (max-width: @screen-xs-max) {
        #content & {
            box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.33);
            border: 0;
            padding: 9px 0;
            position: fixed;
            bottom: 0 !important;
            left: 0 !important;
            right: 0 !important;
            top: auto !important;
            width: 100% !important;
            .scale(1);
            .opacity(1);
            -webkit-transform: translateY(102%);
            transform: translateY(102%);
        }
    }


    &.text-left {
        transform-origin: 0 0;
    }
    .open & {
        .scale(1);
        .opacity(1);
        @media (max-width: @screen-xs-max) {
            #content & {
                -webkit-transform: none;
                transform: none;
            }
        }
    }
    > li > a {
        cursor: pointer;
    }
}




.remark {
    background: @gray-lightest;
    color: @gray;
    padding: 12px 16px;
    a {
        text-decoration: underline;
    }
}
.info-bar {
    border-bottom: 1px solid rgba(0,0,0,0.05);
    color: rgba(0,0,0,0.56);
    padding: 16px 24px 16px 40px;
    position: relative;
    background: @state-info-bg;
    a {
        color: rgba(0,0,0,0.56);
        text-decoration: underline;
        &:hover {
            color: rgba(0,0,0,0.84);
        }
    }
    &.danger-bar, &.success-bar {
        color: rgba(255,255,255,0.84);
        a {
            color: rgba(255,255,255,0.84);
            &:hover {
                color: #fff;
            }
        }
    }
    &:before {
        .icon;
        font-size: @font-size-h3;
        vertical-align: middle;
        content: "\f1f8";
        position: absolute;
        left: 0;
        width: 42px;
        .text-center;
    }
    &.warning-bar {
        background: @state-warning-bg;
        &:before {
            content: "\f1f4";
        }
    }
    &.danger-bar {
        background: @brand-danger;
        &:before {
            content: "\f1f1";
        }
    }
    &.success-bar {
        background: @brand-success;
        &:before {
            content: "\f269";
        }
    }
}

.article {
    p:not([class]), ul:not([class]) li, ol:not([class]) li {
        a {
            text-decoration: underline;
        }
    }
    .card-body > ul:not([class]) {
        .list-unstyled;
        li {
            position: relative;
            padding-left: 16px;
            &:before {
                content: "•";
                color: @brand-primary;
                position: absolute;
                left: 0;
            }
        }
    }
}
// Imagepicker //-------------------------------------------------------------------------------------------------------
.imagePicker {
    max-width: 300px;
    margin: 0 auto;
}
.imagePicker-detail {
    position: relative;
    margin-bottom: @form-group-margin-bottom;
}
.imagePicker-preview {
    .img-responsive;
}
.imagePicker-nav {
    display: block;
    color: @gray-dark;
    position: absolute;
    top: 50%;
    right: 16px;
    font-size: 32px;
    margin-top: -16px;
    border-radius: 50%;
    padding: 0;
    .text-center;
    line-height: 32px;
    height: 32px;
    width: 32px;
    color: @white;
    .transition(all .3s);
    &:hover, &:focus {
        color: @gray-darker;
        background: @white;
    }
    i {
        position: relative;
        right: -1px; 
    }
}

.imagePicker-nav.imagePicker-nav--previous {
    left: 16px;
    right: auto;
    i {
        left: -1px;
        right: auto;
    }
}
.imagePicker-label {
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: bold;
    line-height: 1;
    color: @label-color;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    position: absolute;
    bottom: 0;
    right: 0;
}
.imagePicker-preview.is-loading {
    opacity: .5;
}
#search {
  position: absolute;
  top: -@navbar-height;
  left: 0;
  right: 0;
  height: @navbar-height;
  background: @brand-primary;
  .transition(top .3s ease-in-out);
  .opacity(0);
  z-index: 20;
  .search-toggled & {
    top: 0;
    .opacity(1);
  }
  @media (min-width: @screen-sm-min) {
    background: none;
    right: auto;
    left: 50%;
    width: 360px;
    margin-left: -180px;
    top: 0;
    .opacity(1);
    .text-center;
  }
  @media (min-width: @screen-md-min) {
    width: 480px;
    margin-left: -240px;
  }
}

#search .search-input {
    display: inline-block;
    border: 0;
    height: 32px;
    padding: 0 12px 0 32px;
    margin: 12px 0 12px 15px;
    width: calc(~'100% - 72px');
    color: #fff;
    .rgba-white(.2);
    border-radius: @border-radius-small;
    .transition(all .3s);
    @media (min-width: @screen-sm-min) {
        margin: 12px 0;
        width: 360px;
    }
    @media (min-width: @screen-md-min) {
        height: 40px;
        width: 480px;
        font-size: @font-size-h4;
        padding-left: 48px;
    }

    // &:focus {
    //     &::-webkit-input-placeholder {
    //         color: @gray-light;
    //     }
    //     color: @gray-dark;
    //     background-color: @white !important;
    //     &+i {
    //         color: @gray-dark;
    //     }
    // }
    &::-webkit-input-placeholder {
        // color: #fff;
        color: rgba(255,255,255,.6);
    }
}

#search .search-icon {
    color: #fff;
    font-size: 20px;
    position: absolute;
    left: 16px;
    vertical-align: middle;
    top: 12px;
    line-height: 32px;
    height: 32px;
    vertical-align: top;
    padding: 0 6px;

    @media (min-width: @screen-sm-min) {
        left: 0px;
    }

    @media (min-width: @screen-md-min) {
        left: 8px;
        top: 16px;
    }
}

.tt-menu {
    background: @white;
    position: relative;
    border: 0;
    border-radius: 0 0 2px 2px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
    text-align: left;
    width: 100%;
    .card-footer {
        a {
            display: block;
            .text-center;
        }
    }
}
.tt-suggestion {
    cursor: pointer;
    display: table;
    width: 100%;
    border-bottom: 1px solid @gray-lighter;
    &:hover {
        background: @gray-lightest;
    }
    .card-content {
        vertical-align: middle;
    }
    // dd {
    //     color: @gray;
    // }
}
.twitter-typeahead {
    width: 100%;
}
.tt-cursor {
    background: @gray-lightest;
}
#search .tt-hint {
    color: rgba(255,255,255,.6);
}


.searchItem {
    dd {
        // color: @gray;
        small {
            display: block;
            line-height: @line-height-computed;
            a {
                color: @gray;
                &:hover {
                    color: @gray-darker;
                    text-decoration: underline;
                }
            }
        }
    }
}

.search-advanced {
    text-align: left;
    border-radius: 0 0 2px 2px;
}

.search-advancedToggle {
    padding: 16px 8px;
    color: @white;
    display: block;
    position: absolute;
    right: 0;
    top: 0;

    &:hover,
    &:focus {
        color: @white;

        i {
            background: rgba(0, 0, 0, 0.1);
        }
    }

    i {
        transition: all 0.3s ease-in-out;
        border-radius: 50%;
        line-height: 32px;
        font-size: 24px;
        text-align: center;
        height: 32px;
        width: 32px;
    }
}
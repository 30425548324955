.tooltip {
    font-weight: 500;
    white-space: nowrap;
}
.tooltip-inner {
    max-width: none;
    // .rgba-black(.7);
    background: @gray-dark;
    padding: 6px 10px;
}
.tooltip-arrow {
    display: none;
}
.bootstrap-datetimepicker-widget.dropdown-menu {
    display: block;
    border-radius: 0;
    .transition(none);
    .scale(1);
    .opacity(1);
    transform-origin: 100% 0;
    .open & {
        .scale(1);
        .opacity(1);
    }
}
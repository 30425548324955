.breadcrumb {
    background: transparent;
    border-radius: 0;
    padding: 0 16px;
    margin: 0 auto (@line-height-computed / 2);
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    // max-width: 800px;
    @media (min-width: @screen-sm-min) {
        padding: 0;
    }
    a {
        color: @gray-dark;
        .transition(color .3s);
        &:hover {
            color: @gray-darker;
        }
    }
    .active a {
        color: @gray-darker;
    }
    i {
        font-size: @font-size-h4;
        vertical-align: top;
        position: relative;
        top: 3px;
    }
    li + li:before {
        .icon;
        content: @breadcrumb-separator;
        vertical-align: top;
        position: relative;
        top: 3px;
        font-size: @font-size-h3;
        padding: 0 8px;
    }
}
.image {
  margin: @line-height-computed 0;
  img {
    margin: 0 auto;
    display: block;
  }
}

// Gallery
.gallery {
  font-size: 0;
  margin: @line-height-computed 0;
  position: relative;
  a {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    position: relative;
    // cursor: zoom-in;
    @media (min-width: @screen-sm-min) {
      width: 33.33333333%;
    }
    @media (min-width: @screen-md-min) {
      width: 25%;
    }
    @media (min-width: @screen-lg) {
      width: 20%;
    }
    img {
      display: block;
      margin: 0 auto;
    }
     &:hover {
      &:before, &:after {
        .opacity(1);
      }
    }
    &:before {
      .gradient(transparent, rgba(0,0,0,.7));
      display: block;
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 64px;
      .transition(all .3s);
      .opacity(0);
      z-index: 10;
    }
    &:after {
      .icon;
      content: "\f390";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 32px;
      height: 32px;
      line-height: 30px;
      .text-center;
      color: #fff;
      font-size: @font-size-h2;
      .transition(all .3s);
      .opacity(0);
      z-index: 20;
    }

  }
}

.gallery a img {
    max-width: 100%;
}

.mfp-arrow {
  .transition(all .3s);
  &:before {
    .icon;
    content: '\f29b';
    font-size: 60px;
    width: 100%;
    margin-top: 25px;
    margin-left: 0;
    border: none;
    color: #fff;
  }
  &.mfp-arrow-right {
    &:before {
      content: '\f29c';
    }
  }
  &:before, &:hover {
    opacity: 1;
  }
}
.mfp-arrow:after {
  display: none !important;
}

// Video
.video-embed {
  position: relative;
  display: block;
//  height: 0;
//  padding: 0;
//  overflow: hidden;
//  padding-bottom: 56.25%;
  margin: @line-height-computed 0;
  // .card-padding & {
  //   margin-left: -24px;
  //   margin-right: -24px;
  // }
//  iframe, object, video {
//    position: absolute;
//    top: 0;
//    bottom: 0;
//    left: 0;
//    width: 100%;
//    height: 100%;
//    border: 0;
//  }
}


// .gallery {
//   margin: @line-height-computed 0;
//   ul {
//   .clearfix;
//     .list-unstyled;
//     margin: 0;
//     position: relative;
//   }
//   li {
//     float: left;
//     width: 50%;
//     overflow: hidden;
//     @media (min-width: @screen-sm-min) {
//       width: 33.33333333%;
//     }
//     @media (min-width: @screen-md-min) {
//       width: 25%;
//     }
//     @media (min-width: @screen-lg) {
//       width: 20%;

//     }
//   }
//   a {
//     background: #eee;
//     display: block;
//     padding-top: 100%;
//     position: relative;
//    overflow: hidden;
//     &:hover {
//       &:before, &:after {
//         .opacity(1);
//       }
//     }
//     &:before {
//       .gradient(transparent, rgba(0,0,0,.7));
//       display: block;
//       content: '';
//       position: absolute;
//       bottom: 0;
//       left: 0;
//       right: 0;
//       width: 100%;
//       height: 64px;
//       .transition(all .3s);
//       .opacity(0);
//       z-index: 10;
//     }
//     &:after {
//       .icon;
//       content: "\f232";
//       position: absolute;
//       bottom: 0;
//       right: 0;
//       width: 32px;
//       height: 32px;
//       line-height: 30px;
//       .text-center;
//       color: #fff;
//       font-size: @font-size-h2;
//       .transition(all .3s);
//       .opacity(0);
//       z-index: 20;
//     }
//   }
//   img {
//     position:absolute;
//     left: -100%;
//     right: -100%;
//     top: -100%;
//     bottom: -100%;
//     margin: auto;
//     height: 100%;
//     max-width: none;
//     z-index: 5;
//   }
// }

// Gallery
.mfp-arrow.mfp-arrow-right:before {
    content: "\f2fb";
}
.mfp-arrow.mfp-arrow-left:before {
    content: "\f2fa";
}

// Slideshow
.slideshow {
  background: @gray-darker;
  margin: @line-height-computed 0;
  // .card-padding & {
  //   margin-left: -24px;
  //   margin-right: -24px;
  // }
}
.fotorama {
}
.fotorama__wrap {
  margin: 0 auto;
}
.fotorama__arr,
.fotorama__fullscreen-icon,
.fotorama__video-close,
.fotorama__video-play {
  background: none !important;
}
.fotorama__fullscreen-icon {
  font-size: 32px;
  .text-center;
  line-height: 48px;
  height: 48px;
  width: 48px;
  right: 0;
  top: 0;
  text-shadow: 0 1px 3px rgba(0,0,0,.3);
  color: #fff;
}
.fotorama__fullscreen-icon:after {
  .icon;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  line-height: 48px;
  content: '\f16d';
}
.fotorama--fullscreen .fotorama__fullscreen-icon:after {
  content: '\f16c';
}
.fotorama__arr {
  font-size: @font-size-large * 2.4;
  .text-center;
  line-height: 56px;
  height: 56px;
  width: 56px;
  margin-top: -28px;
  text-shadow: 0 1px 3px rgba(0,0,0,0.3);
  color: #fff;
}
.fotorama__arr--prev {
  left: 0;
}
.fotorama__arr--next {
  right: 0;
}
.fotorama__arr:after {
    .icon;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "\f2fa";
    line-height: 56px;
}
.fotorama__arr.fotorama__arr--next:after {
    content: "\f2fb";
}
.fotorama__dot {
  opacity: 0.5;
  border-color: inherit;
}
.fotorama__thumb-border {
  border-color: @gray-light;
}

.fotorama__caption__wrap {
  .gradient(transparent, rgba(0,0,0,.7));
  color: #fff;
  .text-center;
  width: 100%;
  font-family: @font-family-sans-serif;
  font-size: @font-size-base;
  padding: 16px;
}

.medium-toolbar-arrow-under:after {
  top: 50px;
  border-color: #242424 transparent transparent transparent; }

.medium-toolbar-arrow-over:before {
  top: -8px;
  border-color: transparent transparent #242424 transparent; }

.medium-editor-toolbar {
  border: 1px solid #000;
  background-color: #242424;
  background: -webkit-linear-gradient(top, #242424, rgba(36, 36, 36, 0.75));
  background: linear-gradient(to bottom, #242424, rgba(36, 36, 36, 0.75));
  border-radius: 5px;
  box-shadow: 0 0 3px #000; }
  .medium-editor-toolbar li button {
    min-width: 50px;
    height: 50px;
    border: 0;
    border-right: 1px solid #000;
    border-left: 1px solid #333;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    background-color: #242424;
    color: #fff;
    background: -webkit-linear-gradient(top, #242424, rgba(36, 36, 36, 0.89));
    background: linear-gradient(to bottom, #242424, rgba(36, 36, 36, 0.89));
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-transition: background-color .2s ease-in;
            transition: background-color .2s ease-in; }
    .medium-editor-toolbar li button:hover {
      background-color: #000;
      color: yellow; }
  .medium-editor-toolbar li .medium-editor-button-first {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; }
  .medium-editor-toolbar li .medium-editor-button-last {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px; }
  .medium-editor-toolbar li .medium-editor-button-active {
    background-color: #000;
    color: #fff;
    background: -webkit-linear-gradient(top, #242424, rgba(0, 0, 0, 0.89));
    background: linear-gradient(to bottom, #242424, rgba(0, 0, 0, 0.89)); }

.medium-editor-toolbar-form {
  background: #242424;
  color: #999;
  border-radius: 5px; }
  .medium-editor-toolbar-form .medium-editor-toolbar-input {
    height: 50px;
    background: #242424;
    color: #ccc;
    box-sizing: border-box; }
  .medium-editor-toolbar-form a {
    color: #fff; }

.medium-editor-toolbar-anchor-preview {
  background: #242424;
  color: #fff;
  border-radius: 5px; }

.medium-editor-placeholder:after {
  color: #b3b3b1; }

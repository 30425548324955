.modal-content {
    border: 0;
    border-radius: 0;
    box-shadow: 0 5px 20px rgba(0,0,0,.3);
}

.modal-body {
    .table-wrap {
        margin: -@modal-inner-padding;
        overflow: auto;
    }
}

.modal-header, .event-header {
    background: @gray;
    h4, .close {
        color: #fff;
    }
    .close {
        color: #fff;
        text-shadow: none;
    }
}
.modal-header {
    background: @brand-primary;
    border: 0;
    position: relative;
    padding: 16px 24px;
    .close {
        color: #fff;
        cursor: pointer;
        display: inline-block;
        .transition(all .3s ease-in-out);
        z-index: 20;
        margin: 0;
        .opacity(1);
        padding: 12px 0;
        height: 56px;
        width: 56px;
        .text-center;
        position: absolute;
        right: 0;
        top: 0;
        vertical-align: middle;
        &:hover, &:focus {
            i {
                .rgba-black(.1);
            }
        }
        i {
            .transition(all .3s ease-in-out);
            font-size: @font-size-h2;
            border-radius: 50%;
            padding: 0;
            line-height: 32px;
            width: 32px;
            .text-center;
        }
    }


}


.modal-title {
    color: #fff;
    font-size: @font-size-h3;
    font-weight: 400;
    line-height: 24px;
    // text-transform: uppercase;
}
.modal-footer {
    text-align: left;
}





.pac-container {
    z-index: 9999999999999;
}

.modal {
    .form-actions {
        border-top: 1px solid @gray-light;
        padding-top: 15px;
    }
    .remark {
        margin: -24px -24px 24px;
    }
    .btn-primary {
        border-color: transparent;
    }
}
.modal-columns {
    .row;
    margin: -24px;
    position: relative;
    &:before {
        @media (min-width: @screen-md-min) {
            display: block;
            content: '';
            position: absolute;
            background: @gray-lighter;
            position: absolute;
            width: 1px;
            height: 100%;
            right: 33.33333333%;
        }
    }
}
.modal-column-left {
    .make-md-column(8);
}
.modal-column-right {
    .make-md-column(4);
}
.modal-column-left, .modal-column-right {
    padding: 24px;
}

.modal-fs {
@media (min-width: @screen-sm-min) {
        height: calc(~'100% - 60px');
        width: calc(~'100% - 60px');
        .modal-content {
            height: 100%;
        }
        .modal-body {
            position: absolute;
            width: 100%;
            bottom: 83px;
            top: 56px;
            overflow-y: auto;
        }
        .modal-footer {
            position: absolute;
            bottom: 0;
            width: 100%;
        }
        .modal-columns {
            margin: 0;
            position: absolute;
            top: 0;
            // bottom: 0;
            left: 0;
            right: 0;
            // width: 100%;
            overflow: hidden;
            &:before {
                display: none;
            }
        }
        .dropzone {
            margin: 0;
            position: absolute;
            height: calc(~'100% - 48px');
            width: calc(~'100% - 48px');
            .dz-message {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                height: 160px;            
            }
        }
    }
}
.row--modal {
    margin: -@modal-inner-padding;
}

.second-level-dialog {
    z-index: 1060;
    .modal-dialog {
        margin-top: 50px;
    }
}

.second-level-dialog + .modal-backdrop {
    z-index: 1055;
}
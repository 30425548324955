.tab-nav {
  background: #fff;
  border-bottom: 1px solid @gray-lighter;
  .list-unstyled;
  .text-center;
  padding: 0;
  margin: 0;
  white-space: nowrap;
  overflow: auto;
    // box-shadow: inset 0 -2px 0 0 darken(@gray-light, 5%);
    li {
      display: inline-block;
      line-height: @line-height-computed;
      vertical-align: top;
      font-weight: 500;
    }
    a {
      cursor: pointer;
      display: inline-block;
      color: @gray;
      text-transform: uppercase;
      position: relative;
      width: 100%;
      .transition(all .3s ease-in-out);
      padding: 15px;
      &:hover {
        color: @brand-primary;
      }
      &:after {
        content: "";
        height: 2px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        .transition(all .3s ease-in-out);
        .scale(0);
      }
    }
    .active {
      a {
        color: @brand-primary;
        &:after {
          background: @brand-primary;
          .scale(1);
        }
      }
    }
    .card-body & {
      margin: -16px -16px 16px;
    } 
    .card-body.card-padding & {
      margin: -24px -24px 24px;
    }

    .modal & {
      background: @brand-primary;
      margin: -40px -24px 24px;
      border-bottom: 0;
      a {
        color: saturate(lighten(@brand-primary, 32%), 16%);
        &:hover {
          color: #fff;
        }
      }
    .active {
      a {
        color: #fff;
        &:after {
          background: #fff;
          .scale(1);
        }
      }
    }
    }


  }
form {
    margin: 0 auto;
}
.control-label {
    font-weight: 400;
}
.form-control {
    box-shadow: inset 0 1px 1px rgba(0,0,0,.1);
    &:focus {
        box-shadow: inset 0 1px 1px rgba(0,0,0,.1) !important;
    }
}

label {
    font-weight: 400;
}

.control-label--required:after {
    content: ' *';
    color: @brand-danger;
}
.form-horizontal {
    .control-label--required {
        &:before {
            content: '* ';
            color: @brand-danger;
        }
        &:after {
            display: none;
        }
    }
    .form-group {
        .checkbox {
            margin: 0;
        }
    }
}

.form-actions {
    border-top: 1px solid @gray-lighter;
    margin: 16px -16px 0;
    padding: 16px 16px 0;
    .card-padding & {
        margin: 24px -24px 0;
        padding: 24px 24px 0;
    }
}
.form-group, div[class^="form-control"] {
    margin-bottom: 16px;
}
.form-group {
    clear: both;
    .checkbox {
        margin-bottom: 8px;
    }
}

.help-block {
    color: darken(@gray-light, 20%);
    margin-bottom: 0;
    font-size: @font-size-small;
}
.form-control--xs {
    .make-sm-column(2);
}
.form-control--sm {
    .make-sm-column(4);
}
.form-control--md {
    .make-sm-column(6);
    &.form-control--offset {
        .make-sm-column-offset(3);
    }
}
.form-control--lg {
    .make-sm-column(12);
    label {
        display: block;
    }
}
.form-group--text .form-control {
    .make-sm-column(1);
    float: none !important;
    display: inline;
    margin: 0 4px 0 8px;
}
.form-control--inline {
    display: inline;
    width: auto;
    margin-left: 8px;
}

.formbuilder {
    .form-group {
        max-width: @screen-xs;
        margin-left: auto;
        margin-right: auto;
    }
}
.formBuilder-add {
    margin-top: 24px;
    width: 100%;
}
.formBuilder-add-wrapper {
    text-align: center;
}

.formBuilter-preview {
    border-bottom: 1px solid @gray-lighter;
    position: relative;
    margin: 0 -24px;
    min-height: 40px;
    padding: 16px 24px 0 64px;
    .actions {
        position: absolute;
        right: 16px;
        top: 16px;
    }
    .cell-edit {
        position: absolute;
        top: 16px;
        right: 48px;
        .opacity(0);
    }
    &:hover {
        .cell-edit {
            .opacity(1);
        }
    }
}
.formBuilder-edit {
    // cursor: pointer;
    margin: 0 -24px;
    padding: 16px 24px;
    background: @gray-lightest;
    position: relative;
    .option {
        margin-bottom: 8px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .input-group-addon {
        cursor: move;
        font-size: @font-size-large;
    }
}
.formBuilder-handle {
    display: none;
    position: absolute;
    top: 16px;
    left: 20px;
    z-index: 200;
    cursor: move;

    i {
        vertical-align: middle;
        color: #aaa;
        font-size: 22px;
        line-height: 32px;
    }
}
.is-draggable .formBuilder-handle {
    display: block;
}

.form-control--textarea {
    height: 80px;
}
.option-delete {
    cursor: pointer;
    white-space: nowrap;
    vertical-align: middle;
    padding: 6px 12px;
    font-size: @font-size-large;
    height: 34px;
    color: @gray;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
    &:hover {
        color: @gray-darker;
    }
}

.is-draggable.draggable-list .cell-edit {
    display: inline-block;
}

.draggable-group {
    border-top: 1px solid @gray-lighter;
    list-style: none;
    padding: 0;
    li {
        border-bottom: 1px solid @gray-lighter;
    }
}
.downloads {
    margin: @line-height-computed 0;
    ul {
        .list-unstyled;
    }
    li {
        color: @gray-light;
        padding-left: 32px;
        position: relative;
    }
    a {
        display: inline-block;
        &:hover {
            text-decoration: underline;
        }
        &:before {
            .icon;
            content: '\f220';
            display: inline-block;
            font-size: @font-size-h2;
            vertical-align: top;
            color: @gray-light;
            position: absolute;
            left: 0;
        }
    }
}

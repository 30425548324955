// Permission switch // ------------------------------------------------------------------------------------------------
.permissionSwitch,
.permissionSwitch:hover {
    color: @gray-light;
}
.permissionSwitch.is-inherited {
    opacity: .5;
}
.permissionSwitch.is-allowed {
    color: @brand-success;
}
.permissionSwitch.is-denied {
    color: @brand-danger;
}
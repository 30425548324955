.timeline {
    position: relative;
    .timeline-entry {
    .clearfix;
        position: relative;
        margin: 0 0 16px 120px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    time {
        text-align: right;
        position: absolute;
        left: -120px;
        top: 10px;
    }
    .timeline-label {
        padding-top: 10px;
    }

    &:before {
        display: block;
        content: '';
        bottom: 0px;
        top: 0px;
        left: 140px;
        margin-left: -2px;
        position: absolute;
        height: 100%;
        width: 4px;
        background: @gray-lighter;
    }
    .timeline-icon {
        .pull-left;
        margin-right: 16px;
    }
}